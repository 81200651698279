var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("layers")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t("title.cluster")))]),_c('span',[_vm._v(" ")]),(!_vm.items.parent_id)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","label":"","color":"pink"}},[_vm._v(_vm._s(_vm.$t("common.root")))]):_vm._e(),_c('v-spacer'),_c('mode-selection',{on:{"onSwitchMode":_vm.onSwitchMode}})],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("items.name"))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    (v) => !!v || _vm.$t('rules.required'),
                    // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                    (v) => (v && v.length <= 45) || _vm.$t('rules.max45c'),
                  ],"counter":"45","disabled":_vm.mode},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.parent")))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.parants,"item-text":"name","item-value":"id","disabled":_vm.mode},model:{value:(_vm.items.parent_id),callback:function ($$v) {_vm.$set(_vm.items, "parent_id", $$v)},expression:"items.parent_id"}})],1)],1),_c('v-layout',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("items.tag"))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    (v) => !!v || _vm.$t('rules.required'),
                    (v) => (v && v.length <= 45) || _vm.$t('rules.max45c'),
                  ],"counter":"45","disabled":_vm.mode},model:{value:(_vm.items.tag),callback:function ($$v) {_vm.$set(_vm.items, "tag", $$v)},expression:"items.tag"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.image")))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:[
                    'image-box',
                    _vm.mode ? 'disabled' : '',
                    _vm.error ? 'size' : '',
                  ],on:{"click":function($event){$event.stopPropagation();return _vm.onPickFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDropFile.apply(null, arguments)}}},[(!_vm.image)?_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.$t("discriptions.image")))]):_vm._e(),_c('img',{staticClass:"image",attrs:{"src":_vm.getImageUrl(_vm.image),"alt":""}}),_c('br'),(_vm.image && !_vm.mode)?_c('v-btn',{attrs:{"outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveFile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.remove")))]):_vm._e(),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChangeFile}})],1),(_vm.error)?_c('span',{staticClass:"caption error--text"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.cm_per_pixel")))])]),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    (v) => !v || v >= 0 || _vm.$t('rules.min0n'),
                    (v) =>
                      !v ||
                      /^[\d+(\.\d+)?]+$/.test(v) ||
                      _vm.$t('rules.unusable'),
                  ],"suffix":"cm","disabled":_vm.mode},model:{value:(_vm.items.cm_per_pixel),callback:function ($$v) {_vm.$set(_vm.items, "cm_per_pixel", $$v)},expression:"items.cm_per_pixel"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.att_coef")))])]),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    (v) => !v || v >= 0 || _vm.$t('rules.min0n'),
                    (v) =>
                      !v ||
                      /^[\d+(\.\d+)?]+$/.test(v) ||
                      _vm.$t('rules.unusable'),
                  ],"disabled":_vm.mode},model:{value:(_vm.items.att_coef),callback:function ($$v) {_vm.$set(_vm.items, "att_coef", $$v)},expression:"items.att_coef"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.status")))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{staticStyle:{"margin-top":"3px"},attrs:{"disabled":_vm.mode},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.created")))])]),_c('v-flex',{staticClass:"grey--text",attrs:{"xs6":""}},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.items.created)))])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t("items.updated")))])]),_c('v-flex',{staticClass:"grey--text",attrs:{"xs6":""}},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.items.updated)))])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text",attrs:{"xs2":""}}),_c('v-flex',{staticClass:"draggable-wrap small",attrs:{"xs4":""}},[_c('draggable',{staticClass:"draggable-area small",attrs:{"list":_vm.beacons.map((b, i) => ({ ...b, prevIndex: i })),"options":{
                    group: 'beacons',
                    animation: 300,
                    disabled: _vm.mode,
                  }},on:{"change":_vm.checkCanRemove}},_vm._l((_vm.beacons),function(beacon,i){return _c('v-list',{key:i,staticClass:"draggable-item small"},[_c('v-list-tile',[_c('v-list-tile-action',{staticStyle:{"min-width":"30px"}},[_c('v-icon',[_vm._v(_vm._s(beacon.hasOwnProperty("type") && beacon.type === "device" ? "smartphone" : "bluetooth"))])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('div',[_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(beacon.name))])])]),(
                            beacon.shared_apps &&
                            _vm.user.client_id !== beacon.client_id
                          )?_c('v-list-tile-sub-title',[_c('div',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("App: "+_vm._s(`${beacon.shared_apps .filter((app) => _vm.apps.includes(app.id)) .slice(0, 2) .map((app) => app.name) .join(", ")}${ beacon.shared_apps.filter((app) => _vm.apps.includes(app.id) ).length > 2 ? "..." : "" }`))])])]):_vm._e()],1),(
                          beacon.shared && _vm.user.client_id !== beacon.client_id
                        )?_c('v-list-tile-action',[_c('div',[_c('span',{staticStyle:{"white-space":"nowrap","color":"blue"}},[_vm._v("Shared")])])]):_vm._e()],1)],1)}),1)],1),_c('v-icon',{attrs:{"large":""}},[_vm._v("keyboard_arrow_right")]),_c('v-flex',{staticClass:"draggable-wrap small",attrs:{"xs4":""}},[_c('draggable',{staticClass:"draggable-area destination small",attrs:{"list":_vm.items.beacons,"options":{
                    group: 'beacons',
                    animation: 300,
                    disabled: _vm.mode,
                  }},on:{"change":_vm.checkCanDrop}},_vm._l((_vm.items.beacons.map((b, i) => ({
                      ...b,
                      prevIndex: i,
                    }))),function(item){return _c('v-list',{key:item.id,staticClass:"draggable-item small"},[_c('div',{ref:"listDisplayedBeacons",refInFor:true,attrs:{"data-key":item.prevIndex}},[_c('v-list-tile',[_c('v-list-tile-action',{staticStyle:{"min-width":"30px"}},[_c('v-icon',[_vm._v(_vm._s(item.hasOwnProperty("type") && item.type === "device" ? "smartphone" : "bluetooth"))])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_c('v-layout',{staticStyle:{"width":"100%"},attrs:{"align-center":"","justify-space-between":"","row":""}},[_c('strong',{staticStyle:{"white-space":"nowrap","margin":"8px"}},[_vm._v(_vm._s(item.name))]),((item.x > 0 && item.y > 0) || item.set)?_c('svg',{attrs:{"height":"10","width":"10"}},[_c('circle',{attrs:{"cx":"5","cy":"5","r":"5","fill":"#00897b"}})]):_vm._e()])],1),(
                              item.shared_apps &&
                              _vm.user.client_id !== item.client_id
                            )?_c('v-list-tile-sub-title',[_c('div',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("App: "+_vm._s(`${item.shared_apps .filter((app) => _vm.apps.includes(app.id)) .slice(0, 2) .map((app) => app.name) .join(", ")}${ item.shared_apps.filter((app) => _vm.apps.includes(app.id) ).length > 2 ? "..." : "" }`))])])]):_vm._e()],1),(
                            item.shared && _vm.user.client_id !== item.client_id
                          )?_c('v-list-tile-action',[_c('div',[_c('span',{staticStyle:{"white-space":"nowrap","color":"blue","text-overflow":"ellipsis"}},[_vm._v("Shared")])])]):_vm._e(),(item.displayed && item.type === 'beacon')?_c('beacon-setting',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mode),expression:"!mode"}],key:item.id,attrs:{"selected":item,"index":item.prevIndex,"beaconsData":_vm.items.beacons,"beaconsDisplay":_vm.onFilterBeacons(item.id),"image":_vm.image},on:{"onClose":_vm.onForceUpdate}}):_vm._e(),(item.displayed && item.type === 'device')?_c('device-setting',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mode),expression:"!mode"}],key:item.id,attrs:{"selected":item,"index":item.prevIndex,"beaconsData":_vm.items.beacons,"beaconsDisplay":_vm.onFilterBeacons(item.id),"image":_vm.image},on:{"onClose":_vm.onForceUpdate}}):_vm._e()],1)],1)])}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("common.back")))]),_c('v-spacer'),(!_vm.mode)?_c('delete-confirmation',{attrs:{"loading":_vm.loading},on:{"onDelete":_vm.onDelete}}):_vm._e(),_c('add-beacon-to-cluster-confirmation',{attrs:{"dialog":_vm.checkAddBeaconIndex !== null},on:{"okAction":_vm.moveBeacon,"cancelAction":_vm.cancelMoveBeacon}}),_c('conflicting-beacon-to-cluster-confirmation',{attrs:{"dialog":_vm.checkConflictingBeaconIndex !== null},on:{"okAction":function($event){_vm.checkConflictingBeaconIndex = null}}}),(!_vm.mode)?_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }